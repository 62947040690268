<template>
  <div class="BackStageUser">
    <div class="search">
      <el-row>
        <el-col :offset="22" :span="2">
          <div class="search_input">
            <el-button
              class="btn"
              icon="el-icon-plus"
              type="primary"
              @click="handleCodeAdd()"
              >新增</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <template>
          <el-table-column type="index" align="center" width="150" label="序号">
          </el-table-column>
          <el-table-column prop="userName" align="center" label="图片">
            <template slot-scope="scope">
              <!--scope.row.date -->
              <el-image
                style="height: 167px"
                :src="scope.row.url"
                :preview-src-list="[scope.row.url]"
              >
              </el-image>
            </template>
          </el-table-column>
        </template>
        <el-table-column fixed="right" width="250" label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleLook(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              plain
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="page.pageSize"
          layout="total, prev, pager, next"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="裁切图片"
      :visible.sync="cropperModel"
      custom-class="customClassDialog"
    >
      <!-- 图片裁剪弹框 -->
      <FileUpload
        :Name="cropperName"
        @uploadImgSuccess="handleUploadSuccess"
        :fileUpLoad="fileUpLoad"
        ref="child"
      />
    </el-dialog>
  </div>
</template>

<script>
import MoTable from "../../../components/MoTable/MoTable";
import FileUpload from "../../../components/imgUpLoad/imgUpLoad.vue";
export default {
  name: "BackStageUser",
  components: {
    MoTable,
    FileUpload,
  },
  data() {
    return {
      tableData: [],
      //裁切图片参数
      cropperModel: false,
      edit: "",
      cropperName: "",
      fileUpLoad: {
        width: 340,
        height: 168,
      },
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 11,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.getList();
    },
    // 获取
    async getList() {
      const res = await this.$get("/banner/getList", {
        ...this.page,
      });
      if (res.flag) {
        this.tableData = res.data.rows;
        this.page.total = res.data.total;
      }
    },
    // 删除
    handleDelete(index, row) {
      this.$confirm("确认删除？")
        .then((_) => {
          this.codeDelete(row);
        })
        .catch((_) => {});
    },
    async codeDelete(row) {
      const res = await this.$del("/banner/del/" + row.id);
      if (res.flag) {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 编辑
    handleLook(row) {
      this.cropperName = row.id;
      this.cropperModel = true;
    },
    handleCurrentChange(n) {
      this.page.pageNum = n;
      this.getList();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
        })
        .catch((_) => {});
    },
    // 新增
    handleCodeAdd() {
      this.cropperName = null;
      this.cropperModel = true;
    },
    // 上传获取图片地址
    handleUploadSuccess(data) {
      this.getList();
      this.cropperModel = false;
    },
  },
  computed: {
    rules() {
      return {};
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  width: 100%;
  height: 80px;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 16px;
  .search_input {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    .btn {
      width: 110px;
      height: 40px;
    }
  }
}
.name {
  line-height: 40px;
}
.table {
  margin-top: 20px;
  // height: 100%;
  background-color: #fff;
}
/deep/.el-dialog {
  height: 520px;
  border-radius: 8px;
}
</style>